<template>
  <Button
    class="p-button-only-icon mb-4"
    @click="() => openModal(null)">
    <Icon name="plus" />
  </Button>
  <DataTable :value="$store.state.albums.data" v-model:expandedRows="expandedRows" dataKey="id"  responsiveLayout="scroll">
    <Column :expander="true" headerStyle="width: 3rem" />
    <Column>
      <template #body="slotProps">
        <div :class="$style.imgPreview">
          <img :src="slotProps.data.img_preview" alt="">
        </div>
      </template>
    </Column>
    <Column field="name" :header="$t('photos_page.name')"></Column>
    <Column field="all_photos_count" :header="$t('photos_page.all_photos')"></Column>
    <Column field="all_processed_photos_count" :header="$t('photos_page.all_processed_photos')"></Column>
    <Column>
      <template #body="slotProps">
        <Button
          class="p-button-only-icon"
          @click="() => openModal(slotProps.data.id)">
          <Icon name="plus" />
        </Button>
      </template>
    </Column>

    <template #expansion="slotProps">
      <DataTable :value="slotProps.data.subsections" responsiveLayout="scroll">
        <Column field="name" :header="$t('photos_page.name')">
          <template #body="slotProps">
            <router-link class="_link" :to="{ name: 'PhotoAlbum', params: { photosID: slotProps.data.id } }" >{{slotProps.data.name}}</router-link>
          </template>
        </Column>
        <Column field="photos_count" :header="$t('photos_page.photos')"></Column>
        <Column field="processed_photos_count" :header="$t('photos_page.processed_photos')"></Column>
      </DataTable>
    </template>
  </DataTable>

  <Dialog v-model:visible="isShow" :modal="true" :draggable="false">
    <div :class="$style.modal">
      <Toolbar class="p-4">
        <template #left>
          <p class="_heading-2" v-t="'photos_page.added_section'" />
        </template>
        <template #right>
          <Button @click="closeModal" class="p-button-outlined p-button-gray p-button-rounded p-button-only-icon">
            <Icon name="close" />
          </Button>
        </template>
      </Toolbar>

      <form @submit.prevent="submit" :class="$style.content">
        <label class="p-field mb-4">
          <span class="p-label" v-t="'photos_page.name'"></span>
          <InputText type="text" v-model="model.name" />
        </label>
        <div class="flex">
          <Button type="submit" v-t="'save_change'" class="ml-auto" />
        </div>
      </form>
    </div>
  </Dialog>
</template>

<script>

import { onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';

import { Icon } from '../../components/ui'

export default {
  components: {
    DataTable,
    Column,
    Button,
    Icon,
    Dialog,
    Toolbar,
    InputText,
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    const { t }= useI18n()

    const expandedRows = ref([])
    const isShow = ref(false)
    const model = reactive({
      parent_id: null,
      name: null
    })

    store.dispatch('albums/reqBase')

    const openModal = (parent_id) => {
      isShow.value = true
      model.parent_id = parent_id
    }

    const closeModal = () => {
      isShow.value = false
      model.parent_id = null
      model.name = null
    }

    const submit = () => {
      store.dispatch('albums/sendBase', { body: model, callback: closeModal, toast, t })
    }
    return {
      isShow,
      closeModal,
      openModal,
      model,
      expandedRows,
      submit,
    }
  }
}
</script>

<style lang="scss" module>
  .modal {
    background-color: #fff;
    border-radius: $radius;
    min-width: 360px;
    width: 100%;
  }

  .imgPreview {
    width: 80px;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    padding: 20px;
  }
</style>